// 单图片上传

<template>
  <div class="upload" :style="`width: ${width}px; height: ${width}px`">
    <el-upload
      ref="uploadImg"
      :headers="headers"
      :action="action"
      name="attach"
      :accept="accept"
      :limit="1"
      :show-file-list="false"
      class="uploader-img"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
    >
      <el-progress v-if="status === 'uploading'" type="circle" :width="width - 24" :percentage="progress"></el-progress>

      <div v-if="imgUrl && status === 'success'" class="img-box">
        <img :src="imgUrl" class="img" />
        <div class="mask">
          <i class="el-icon-edit" slot="trigger"></i>
          <i class="el-icon-delete" @click.stop="handleDelete"></i>
        </div>
      </div>

      <i v-if="!imgUrl && status === 'success'" class="el-icon-plus uploader-img-icon"></i>

      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    imgUrl: { type: String, default: '' },
    accept: { type: String, default: 'image/jpeg,image/png' },
    size: { type: [Number, String], default: 100 },
    tip: { type: String, default: '' },
    width: { type: [Number, String], default: '150' }
  },

  data() {
    return {
      headers: { Authorization: localStorage.getItem('token') },
      action: process.env.VUE_APP_BASE_API + '/serve-user/app-api/v1/upload',

      status: 'success',
      progress: 0
    }
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    // 上传前触发
    beforeUpload(file) {
      const isLtSize = file.size / 1024 / 1024 < this.size

      if (!isLtSize) {
        this.$message.error('上传图片的大小不能超过 ' + this.size + 'MB!')
      }

      return isLtSize
    },

    // 文件上传时的钩子
    onProgress(event, file, fileList) {
      this.status = 'uploading'
      this.progress = parseInt(event.percent)
    },

    // 上传成功时触发
    onSuccess(res, file) {
      this.status = 'success'
      this.$emit('update:imgUrl', res.data[0])
      this.$emit('onSuccess', res, file)
      this.$refs.uploadImg.clearFiles() // 上传完成后清空已上传的文件列表
    },

    // 删除文件
    handleDelete() {
      this.$emit('update:imgUrl', null)
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  line-height: normal;

  /deep/ .uploader-img {
    width: 100%;
    height: 100%;

    .el-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      overflow: hidden;
      &:hover {
        border-color: #409eff;
      }

      .el-progress {
        margin: 12px;
      }

      .img-box {
        position: relative;
        width: 100%;
        height: 100%;
        &:hover .mask {
          display: flex;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.5);
          font-size: 20px;
          cursor: default;

          i {
            margin: 10px;
            cursor: pointer;
          }
        }
      }

      .uploader-img-icon {
        font-size: 28px;
        color: #8c939d;
        text-align: center;
      }
    }

    .el-upload__tip {
    }
  }
}
</style>
